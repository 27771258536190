import * as React from "react"
import {
  ChakraProvider, Box, Flex, Image, 
  Text, ButtonGroup, IconButton,
  Link, Center, Heading, Button,
  VStack, Stack, Divider, Progress, useBreakpointValue,
  Grid, SimpleGrid, theme,useColorModeValue as mode,
  Tabs, TabList, TabPanels, Tab, TabPanel
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { ReactComponent as Logo } from "./logo2.svg"
import { ReactComponent as Avatar } from './avatar.svg'
import { FaGithub, FaLinkedin, FaWordpress, FaCameraRetro, FaSalesforce } from 'react-icons/fa'
import { SiHtml5,SiJavascript, SiAdobelightroomclassic, SiCss3,SiPhp, SiAdobeillustrator, SiAdobephotoshop, SiApple, SiFigma, SiSalesforce } from "react-icons/si";
import { Feature } from './Feature'
import { Description } from './Description'
import { getDayOfWeek } from './utils/getDayOfWeek'
import background from './images/bg.jpg'
import telmoProfile from './images/telmo-profile.jpg'

import ReactGA from "react-ga4"
ReactGA.initialize("G-BSYQ6GCFGH");

//#7caccc #ddeaf2 

//const TP_bgcolor = () => (mode("#fffff", "gray.800"))
const T_gradient = () => (mode('linear(to-r,  #ffffffbb,  #ffffffdd)', 'linear(to-r,  #253b53bb,  #253b53dd)'))

const bgPanel = () => (mode("#F7FbFF", "#253b53"))
const colorPanel = () => (mode("#253b53", "#F7FbFF"))

const bgFooter = () => (mode("#FBFDFE", "#253b53"))
const colorFooter = () => (mode("#253b53", "#E2EAF2"))

const bgImage = () => (mode ("#8AA9CB" , "#253b53"))

const now = new Date()
const dayOfWeek = getDayOfWeek(now.getDate(), now.getMonth(), now.getFullYear())


export const App = () => (

  <ChakraProvider theme={theme}>
    <Box w="100%" h="10px" bg={bgImage} />

    <Flex
      w={'full'}
      /* h={'35vh'} */
      backgroundImage={`url(${background})`}
      backgroundSize={'cover'}
      backgroundPosition={'center center'}
      >
      <VStack
        w={'full'}
        justify={'center'}
        /* px={useBreakpointValue({ base: 4, md: 8 })} */
        bgGradient={T_gradient}
      >
      <Box
        mx="auto"
        px={{ base: '6', lg: '8' }}
        py={{ base: '16', sm: '20' }}
        textAlign="center"
      >
        
        <Center>
            <Avatar width="70" fill="#8AA9CB" /* stroke="red" stroke-width="5" *//>
        </Center>
      
        <Center py="4">
            <Logo width="180" fill="#8AA9CB" /* stroke="white" stroke-width="1"*/ />
        </Center>

        <Center>
          <Text py="4" color={colorFooter} as="kbd" fontSize="xl" >
                creative software engineer
            </Text>
        </Center>
      
        <ButtonGroup marginStart={{ md: 'auto' }} size="xl" variant="ghost">
          {/* <Link href="https://t.me/telmopereiro" isExternal><IconButton fontSize="2xl" mx="3"  aria-label="Telegram" color={colorPanel} icon={<FaTelegram />} /></Link> */}
          <Link href="https://www.linkedin.com/in/telmopereiro" isExternal><IconButton fontSize="2xl" mx="3"  aria-label="LinkedIn" color={colorPanel} icon={<FaLinkedin />} /></Link>
          <Link href="https://github.com/telmov" isExternal><IconButton fontSize="2xl" mx="3"  aria-label="Github" color={colorPanel} icon={<FaGithub />} /></Link>
          {/* <Link href="http://flickr.com/telmopereiro" isExternal><IconButton fontSize="2xl" mx="3"  aria-label="Flickr" color={colorPanel} icon={<FaFlickr />} /></Link> */}
        </ButtonGroup> 

      </Box>
      </VStack>

    </Flex>

    {/* TABS */}

    <Tabs align="center" w="100%" isLazy>
        <TabList as="kbd">
          <Tab>Code</Tab>
          <Tab>Design</Tab>
          <Tab>About</Tab>
          <ColorModeSwitcher justifySelf="flex-end" />
        </TabList>

        <Box mb={0} bg={bgPanel} color={colorPanel}>
        <TabPanels>

          <TabPanel align="left">{/* CODE */}
            <Box as="section" py="12" /* bgGradient={TP_bgcolor1} */>
              <Box maxW={{ base: 'xl', md: '5xl' }} mx="auto" px={{ base: '6', md: '8' }}>
                <SimpleGrid columns={{ base: 2, md: 3 }} spacingX="10" spacingY="14">
                  <Feature title="Wordpress" progr="95" icon={ <FaWordpress /> }>
                    WooCommerce
                  </Feature>
                  <Feature title="PHP" progr="88" icon={<SiPhp />}>
                    PHP
                  </Feature>
                  <Feature title="Salesforce" progr="50" icon={<FaSalesforce />}>
                    Salesforce Commerce Cloud
                  </Feature>

                  <Feature title="HTML5" progr="95" icon={<SiHtml5 />}>
                    HTML
                  </Feature>
                  <Feature title="CSS3" progr="95" icon={<SiCss3 />}>
                    LESS / SASS
                  </Feature>
                  <Feature title="Javascript" progr="70" icon={<SiJavascript />}>
                    jQuery, React, Node
                  </Feature>
                </SimpleGrid>
              </Box>
            </Box>
          </TabPanel>

          <TabPanel align="left"> {/* DESIGN */}
            <Box as="section" py="12" /* bgGradient={TP_bgcolor1} */>
              <Box maxW={{ base: 'xl', md: '5xl' }} mx="auto" px={{ base: '6', md: '8' }}>
                <SimpleGrid columns={{ base: 2, md: 3 }} spacingX="10" spacingY="14">
                  <Feature title="Adobe Ilustrator" progr="90" icon={<SiAdobeillustrator />}>
                    Branding / Vector
                  </Feature>
                  <Feature title="Adobe Photoshop" progr="90" icon={<SiAdobephotoshop />}>
                    Diseño | Flyers
                  </Feature>
                  <Feature title="Figma" progr="60" icon={<SiFigma />}>
                    UI / UX
                  </Feature>
                  <Feature title="Lightroom" progr="90" icon={<SiAdobelightroomclassic />}>
                    Postproducción fotográfica
                  </Feature>
                  <Feature title="Final Cut Pro" progr="90" icon={<SiApple />}>
                    Video
                  </Feature>
                  <Feature title="Canon 6D" progr="90" icon={<FaCameraRetro />}>
                    Product photo
                  </Feature>
                </SimpleGrid>
              </Box>
            </Box>
          </TabPanel>


          <TabPanel> {/* ABOUT */}
          
            <Box py="10">
              <Image
                  borderRadius='full'
                  boxSize='200px'
                  src={telmoProfile}
                  alt='Telmo Pereiro'
                />
            </Box>

            <Box as="section" /* bgGradient={TP_bgcolor1} */>
              <Text color={colorFooter} noOfLines={[4]} as="kbd" fontSize="lg">
              ¡Hola! Soy Telmo.
              </Text>
              <Text color={colorFooter} noOfLines={[4]} as="kbd" fontSize="lg">
              Soy Ingeniero de Software con un Máster de Arte y Comunicación. Estoy especializado en soluciones web para comercio electrónico.
              </Text>
              <Text color={colorFooter} noOfLines={[4]} as="kbd" fontSize="lg">
              Mi perfil híbrido me ha permitido colaborar con startups y obtener un aprendizaje de gran valor en su proceso de crecimiento.
              </Text>
              <Text color={colorFooter} noOfLines={[4]} as="kbd" fontSize="lg">
              En la actualidad, estoy involucrado en proyectos relacionados con Salesforce Commerce Cloud.
              </Text>
            </Box>          
          </TabPanel>

        </TabPanels>
      </Box>
      </Tabs>

    {/* FOOTER */}
      <Center as="footer" bg={bgFooter} color={colorFooter} h='150px'>
          <Stack
              my={{ base: '6', md: 0 }}
              direction={{ base: 'column', md: 'row' }}
              marginStart={{ md: '8' }}
              fontSize="m"
              spacing={{ base: '2', md: '8' }}
              textAlign={{ base: 'center', md: 'start' }}
            >
              <Center><Avatar width="30" fill="#8AA9CB"/></Center>
              <Text as="kbd">&copy; {new Date().getFullYear()}</Text>
              {/* <Link as="kbd" href="mailto:hola@telmopereiro.com">hola@telmopereiro.com</Link> */}
          </Stack>
      </Center>
      {/* <Box w="100%" h="2px" bg='#8AA9CB' /> */}
  </ChakraProvider>
)
