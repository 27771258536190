import { Box, Stack, StackDivider, Progress,Text, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'

interface FeatureProps {
  title: string
  children: string
  icon: React.ReactElement
  progr: string
}

export const Feature = (props: FeatureProps) => {
  const { title, children, icon, progr } = props
  return (
    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
      <Box textAlign="center" fontSize="4xl">{icon}</Box>
      <Stack spacing="1" w="100%">
        <Text as="kbd" fontWeight="extrabold" fontSize="md">
          {title}
        </Text>
        <Box py="2" color={mode('gray.900', 'gray.100')}>
          <Progress isAnimated size='xs' colorScheme='blue' value={progr} />
        </Box>
        <Box color={mode('gray.900', 'gray.100')}>
          <Text as="kbd" fontWeight="light" fontSize="sm">
            {children}
          </Text>
        </Box>
      </Stack>
    </Stack>
  )
}